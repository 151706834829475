@font-face {
  font-family: Cairo;
  src: url(./assets/fonts/Cairo-Regular.ttf);
  font-weight: 400;
}


@font-face {
  font-family: Cairo;
  src: url(./assets/fonts/Cairo-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: Cairo;
  src: url(./assets/fonts/Cairo-Bold.ttf);
  font-weight: 700;
}
body {
  margin: 0;
  font-family: Cairo;
  text-align: right;
}

