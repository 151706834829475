.App {
  background-image: url(assets/imgs/bg.png);
  background-color: #F6F7FB;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 24px;
  text-align: right;
}

.App .App-wrapper {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 24px 0;
}
.alert {
  bottom: 40px;
}
.btn{
  font-size: 16px;
  color: #121432;
  border-radius: 15px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  font-weight: 500;
  padding: 10px 14px;
}


.btn-success{
  background-color: #D4EDDA;
}
.btn-danger{
  background-color: #F5C6CB;
}
.btn-primary{
  background-color: #DCDEF4;
}
.btn-primary:focus,.btn-primary:hover,.btn-primary:active{
  background-color: #888fdb;
}
.form-control,.form-control:focus{
  background-color: #F6F7FB;
  border: 0;
  outline: 0;
  min-height: 100px;
  border-radius:15px ;
  margin-top: 40px;
  box-shadow: none;
}
.data-list h2 {
  text-align: center;
  color: #233862;
  margin-bottom: 16px;
}

.data-list ul {
  max-height: calc(100vh - 182px);
  overflow: auto;
  padding: 0;

}

.data-list ul li {
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 16px;
  text-align: right;
  color: #121432;
}

.data-list ul li h3 {
  font-size: 14px;
  font-weight: 700;
}

.data-list ul li span {
  font-size: 10px;
  font-weight: 300;
  margin-left: 24px;
  line-height: 1.6;
}
.data-list ul li .list-meta{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}


.new-orders ul li {
  background-color: #EBECF8;
  justify-content: flex-start;
  animation: enterAnimation .6s ease-in-out;
}


.in-progress ul li {
  background-color: #FAF8FB;
  justify-content: space-between;
  animation: enterAnimation .6s ease-in-out;
}
.in-progress ul li .btn-success{
  background-color: #81DEA3;
  color: #fff;
}
.v-line {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #EBECF8;
  width: 5px;
  left: 50%;
  border-radius: 2.5px;
}

.relative {
  position: relative;
}

.details-wrapper {
  max-height: 500px;
  overflow: auto;
}

.order-details-item {
  background-color: #EBECF8;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #EBECF8;
}

.order-details-item:nth-child(2n) {
  background-color: #fff;
}

.order-details-item .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.order-details-item .header img {
  width: 39px;
  height: 39px;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 16px;
}

.order-details-item .header span {
  font-size: 14px;
  font-weight: 700;
  color: #121432;
}

.order-details-item .order-content {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.order-details-item .order-content h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.order-details-item .order-content .right-side {
  flex-basis: 40%;
  max-width: 40%;
  text-align: right;
}

.order-details-item .order-content .order-content-item {
  padding: 8px 16px;
  background-color: #fff;
  color: #121432;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  height: 38px;
}

.order-details-item:nth-child(2n) .order-content .order-content-item {
  background-color: #F6F7FB;
}

.order-details-item .order-content .order-content-item span {
  color: rgba(18, 20, 50, 0.5);
  font-weight: 400;
  margin-right: 16px;

}

.order-details-item .order-content .middle {
  text-align: center;
  flex-basis: 20%;
  max-width: 20%;
}

.order-details-item .order-content .middle span {
  height: 38px;
  display: flex;
  position: relative;
  margin-bottom: 8px;
}

.order-details-item .order-content .middle span.ismine span {

  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #D4EDDA;
  color: #559163;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-details-item .order-content .middle span.notmine::after {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order-details-item:nth-child(2n) .order-content .middle span.notmine::after {
  background-color: #F6F7FB;
  border-color: #F6F7FB;
}

.order-details-item:nth-child(2n) .order-content .middle span.ismine::after {
  border-color: #F6F7FB;
}

.order-details-item .left-side {
  flex-basis: 40%;
  max-width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.order-details-item .left-side .notes {
  background-color: #fff;
  border-radius: 7px;
  padding: 8px;
  color: rgba(18, 20, 50, 0.5);
  font-size: 14px;
  min-height: 80px;
  text-align: right;
  width: 100%;
}

.order-details-item:nth-child(2n) .left-side .notes {
  background-color: #F6F7FB;
}

.userbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px ;
  font-size: 14px;
  font-weight: 400;
  background: #F6F7FB;
  color: #121432;
  border-radius: 7px;
}
.userbox h6{
  font-size: 14px;
}

.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 32px;
}

.qrCode h6 {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 24px;
}

.modal-header .close {
  margin: 0 auto 0 0;
  padding: 5px;
}
.modal-header .close:focus{
  outline: 0;
}
.sweet-modal .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 80vw;
}
.modal-content{
  border-radius: 15px;
}
.sweet-modal .modal-body {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.modal-header,.modal-footer{
  border: 0;
}
.sweet-modal .modal-footer {
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 0px;
}
*{
  scrollbar-width: none;
}
@media(max-width:1200px){
  .modal-xl{
    max-width: 90%;
  }
  
}
@media(max-width:767px){
  .modal-xl{
    max-width: 95%;
  }
  .order-details-item .order-content .right-side{
    flex-basis: 80%;
    max-width: 80%;
  }
  .order-details-item .left-side{
    flex-basis: 100%;
    max-width: 100%;
  }
  .v-line{
    display: none;
  }
}
@keyframes enterAnimation{
  from{
    transform: translateX(200px);
  }
  to{
    transform: translateX(0);
  }
}